import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import React from 'react';
import MediaQuery from 'react-responsive';
import ArrowRight from '../icons/arrow_right_main.svg';
import * as styles from './contact.module.css';
import Markdown from './markdown';
import ScrollableContainer from './scrollableContainer';

const Contact = ({ customContactMethods }) => {
  const { locale } = useIntl();
  const {
    allContentfulContactComponent: { nodes },
  } = useStaticQuery(graphql`
    query MethodsQuery {
      allContentfulContactComponent(
        filter: { name: { eq: "Contact component" } }
      ) {
        nodes {
          node_locale
          overline
          title
          subtitle
          methods {
            linkText
            link
            extraLink
            extraLinkText
            title
            description {
              description
            }
            icon {
              title
              file {
                url
              }
            }
          }
        }
      }
    }
  `);

  const data = nodes.find(i => i.node_locale === locale);

  const contactMethods = customContactMethods
    ? customContactMethods.methods
    : data.methods;
  const contactMethodsContent = (
    <div className={styles.contactMethods}>
      {contactMethods.map((method, index) => {
        const isChatLink = method.link === 'OpenChat';

        const link = isChatLink ? (
          <button
            className={styles.link}
            onClick={e => {
              e.preventDefault();
              window.$zopim.livechat.window.show();
            }}
          >
            {method.linkText} <ArrowRight />
          </button>
        ) : (
          <a
            className={styles.link}
            href={method.link}
            target="_blank"
            rel="noreferrer noopener"
          >
            {method.linkText} <ArrowRight />
          </a>
        );

        return (
          <div key={index} className={styles.contactMethod}>
            <img
              className={styles.icon}
              src={method.icon.file.url}
              alt={method.icon.title}
            />
            <div className={styles.contactMethodText}>
              <div className={styles.contactMethodTitle}>{method.title}</div>
              <div className="text-small">
                <Markdown>{method.description.description}</Markdown>
              </div>
            </div>
            <div className={styles.linkWrapper}>
              {link}
              {method.extraLinkText && (
                <a
                  className={styles.extraLink}
                  href={method.extraLink}
                  target={`${
                    method.extraLink.includes('http') ? '_blank' : ''
                  }`}
                >
                  {method.extraLinkText}
                </a>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );

  const content = (
    <div className={styles.contactDiv}>
      <div className={`container ${styles.contact}`}>
        <div className={styles.contactMethodsHeader}>
          <h1>{data.overline}</h1>
          <p className={styles.subtitle}>{data.title}</p>
          <p>{data.subtitle}</p>
        </div>

        <MediaQuery minWidth={1058}>{contactMethodsContent}</MediaQuery>

        <MediaQuery maxWidth={1058}>
          <ScrollableContainer>{contactMethodsContent}</ScrollableContainer>
        </MediaQuery>
      </div>
    </div>
  );

  return <div>{content}</div>;
};

Contact.propTypes = {
  from: PropTypes.string,
  isHjalp: PropTypes.bool,
  isLargeContact: PropTypes.bool,
  headerText: PropTypes.string,
  contactMethods: PropTypes.arrayOf(PropTypes.object),
  hideFAQ: PropTypes.bool,
  customContactMethods: PropTypes.object,
};

Contact.defaultProps = {
  isHjalp: false,
};

export default Contact;
